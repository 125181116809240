body {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #f2f2f2;
}
h1 {
  text-align: center;
  color: #333;
}
.internship {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
}
h2 {
  color: #007bff;
  margin-bottom: 10px;
}
p {
  color: #555;
  line-height: 1.6;
}