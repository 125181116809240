.about-us-header {
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.about-us * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-us-body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
}

.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.about-us h1,
.about-us h2,
.about-us h3 {
    color: #26c08c;
}

.about-us-h1 {
    font-size: 3em;
    margin-bottom: 20px;
    text-align: center;
}

.about-us-h2 {
    font-size: 2.5em;
    margin-bottom: 15px;
}

.about-us-h3 {
    font-size: 2em;
    margin-bottom: 10px;
}

.about-us p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.8;
}

.about-us ul {
    margin: 20px 0;
    padding-left: 40px;
}

.about-us ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.about-us-section {
    margin-bottom: 50px;
}

.about-us-section-title {
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.about-us-footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
    margin-top: 40px;
}

@media (max-width: 768px) {
    .about-us-h1 {
        font-size: 2.5em;
    }

    .about-us-h2 {
        font-size: 2em;
    }

    .about-us-h3 {
        font-size: 1.5em;
    }

    .about-us p,
    .about-us ul li {
        font-size: 1em;
    }
}