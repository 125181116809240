body {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 0;
    background-color: #f9f9f9;
}
h1 {
    text-align: center;
    color: #333;
}
.subject {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
}
h2 {
    color: #0056b3;
    margin-bottom: 10px;
}
p {
    color: #444;
    line-height: 1.6;
}
.scheme {
    font-weight: bold;
    color: #d9534f;
}