* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-us-page {
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #4CAF50, #2196F3);
    color: #333;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.contact-container {
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.5s ease-in-out;
}

.contact-us-page h1 {
    text-align: center;
    color: #333;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.contact-us-page h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #333;
}

.contact-us-page p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
}

.contact-us-page .contact-info,
.contact-us-page .form-link,
.contact-us-page .social-media {
    margin-bottom: 40px;
}

.contact-us-page .form-link a,
.contact-us-page .social-media a {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1.2em;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.contact-us-page .form-link a {
    background-color: #4CAF50;
}

.contact-us-page .form-link a:hover {
    background-color: #45a049;
    transform: translateY(-3px);
}

.contact-us-page .social-media a {
    margin-right: 15px;
    background-color: #555;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.contact-us-page .social-media a i {
    font-size: 20px;
}

.contact-us-page .social-media a.facebook { background-color: #3b5998; }
.contact-us-page .social-media a.twitter { background-color: #1da1f2; }
.contact-us-page .social-media a.instagram { background-color: #e4405f; }
.contact-us-page .social-media a.linkedin { background-color: #0e76a8; }

.contact-us-page .social-media a i {
    margin-right: 8px;
}

.contact-us-page .social-media a:hover {
    transform: translateY(-3px);
    opacity: 0.85;
}

.contact-us-page .social-media a:last-child {
    margin-right: 0;
}

.contact-us-page footer {
    text-align: center;
    margin-top: 20px;
    font-size: 1em;
    color: #777;
}

@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-30px); }
    100% { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-us-page {
        padding: 10px;
    }
    .contact-us-page .container {
        padding: 20px;
    }
    .contact-us-page h1 {
        font-size: 2em;
    }
    .contact-us-page h2 {
        font-size: 1.5em;
    }
    .contact-us-page p {
        font-size: 1em;
    }
    .contact-us-page .form-link a,
    .contact-us-page .social-media a {
        padding: 10px 20px;
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .contact-us-page .social-media a {
        display: block;
        margin-bottom: 15px;
    }
    .contact-us-page .social-media a:last-child {
        margin-bottom: 0;
    }
    .contact-us-page h1 {
        font-size: 1.8em;
    }
    .contact-us-page h2 {
        font-size: 1.3em;
    }
    .contact-us-page p {
        font-size: 0.9em;
    }
    .contact-us-page .form-link a,
    .contact-us-page .social-media a {
        font-size: 0.9em;
        padding: 8px 15px;
    }
}
